<template functional>
    <div class="media-content">
        <h3>
            {{ $options.methods.formatEmptyString(props.language.name) }}
        </h3>
        <small v-if="props.extended">
            {{ $options.methods.formatEmptyString(props.language.alias) }}
        </small>
    </div>
</template>

<script>
  import { formatEmptyString } from "@core/filters";

  export default {
    name: "LanguageOutput",
    props: {
      language: {
        type: Object,
        required: true
      },
      extended: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      formatEmptyString
    }
  };
</script>

<style scoped></style>
